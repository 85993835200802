* {
  font-family: 'Open Sans', sans-serif;

  margin: 0;

  color: #f0fafa;
  background-color: #564d4d;
}

h2 {
  font-size: 3.5em;
  font-weight: 300;

  padding: 0.5em 0 1em;

  color: #4fc3c2;
}

h3 {
  font-size: 1.5em;
  font-weight: 300;
}

h5 {
  font-size: 2em;
  font-weight: 300;

  padding-bottom: 0.5em;

  color: #ffd23f;
}

h6 {
  font-size: 1em;
  font-weight: 400;
}

p {
  line-height: 1.5em;

  padding-bottom: 1em;
}

.navbar {
  position: relative;
  z-index: 999;

  display: flex;

  margin: 0 auto;
  padding: 1em 10em;

  background-color: #564d4d;
  box-shadow: 0 6px 6px -2px #4c4343;

  align-items: center;
}

@media (max-width: 900px) {
  .navbar {
    padding: 1em 2em;
  }
}

.navbar * {
  background-color: #564d4d;
}

.icon {
  padding: 1em;
}

.navbar .links {
  display: flex;

  margin-left: auto;

  gap: 1.5em;
}

.links {
  padding-left: 2em;
}

.navbar a {
  font-size: 1.2rem;

  text-decoration: none;

  color: #f0fafa;
}
.navbar a:hover {
  color: #ffd23f;
}

.hero {
  display: flex;

  min-height: 31em;
  padding: 1em 10em;

  align-items: center;
}

.hero-text {
  padding: 5em 0;

  flex: 50%;
}

.hero-img-cont,
.about-img-cont {
  flex: 50%;
}

.hero h2 {
  font-size: 3.5em;
  font-weight: 700;

  padding: 0.2em 0;

  color: #f0fafa;
}

.blob {
  max-width: 100%;
}

.projects,
.about {
  background-color: #4c4343;
}

.about-sec-cont {
  gap: 2em;
  display: flex;
  align-items: center;
}

.projects,
.technologies,
.about,
.contact {
  display: flex;
  flex-direction: column;

  padding: 1em 10em 5em 10em;

  align-items: center;
}

.projects *,
.about * {
  background-color: #4c4343;
}

.proj-cont {
  display: flex;

  padding: 1em;

  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  background-color: #564d4d;
  box-shadow: 2px 2px 0 1px #ffd23f;
}

@media (max-width: 900px) {
  .proj-cont,
  .hero,
  .about,
  .about-sec-cont,
  .technologies {
    flex-direction: column;

    padding: 2em;

    gap: 0;
  }
}

.proj-cont * {
  background-color: #564d4d;
}

.proj-media-cont {
  display: flex;

  align-items: center;
}

.proj-media {
  max-width: 30em;
}

@media (max-width: 900px) {
  .proj-media {
    max-width: 25em;
  }
}

.technologies-cont {
  text-align: center;
}

.proj-sec-cont {
  display: flex;
  flex-direction: column;

  padding-top: 2em 10em;

  gap: 3em;
}

.about-sec-cont {
  display: flex;
}

.proj-info-cont,
.about-text-cont {
  display: flex;
  flex-direction: column;

  padding: 2em;

  flex: 50%;
}

.contact-sec-cont {
  display: flex;
  flex-direction: column;

  padding: 3em;

  border-radius: 1em;
  background-color: #4c4343;

  align-items: center;
}

.contact-sec-cont * {
  background-color: #4c4343;
}

.contact-form {
  display: flex;
  flex-direction: column;

  align-items: center;
}

input[type='text'],
textarea[type='text'] {
  display: flex;

  min-width: 20em;
  margin: 0.6em 0;
  padding: 1em;

  color: #4c4343;
  border: none;
  border-radius: 0.5em;
  background-color: #f0fafa;
}

textarea[type='text'] {
  min-height: 10em;

  resize: none;
}

input[type='text']:focus,
textarea[type='text']:focus {
  border: 3px solid #ffd23f;
  outline: none;

  caret-color: #4fc3c2;
}

::placeholder {
  padding-left: 0.2em;
}

.footer {
  position: relative;

  display: flex;

  padding: 1em;

  border-top: 1px solid #4c4343;

  justify-content: center;
}

.button {
  font-size: 1.2em;
  font-weight: 600;

  display: flex;

  width: 150px;
  height: 40px;

  cursor: pointer;
  text-decoration: none;

  color: #4fc3c2;
  border: none;
  border-radius: 0.5em;
  background-color: #f0fafa;
  box-shadow: -2px 2px 0 1px #4fc3c2;

  align-items: center;
  justify-content: center;
}

.button:hover {
  color: #f0fafa;
  background-color: #4fc3c2;
  box-shadow: -2px 2px 0 1px #f0fafa;
}

#submit-btn {
  margin: 0.5em;

  box-shadow: -2px 2px 0 1px #ffd23f;
}

#submit-btn:hover {
  color: #f0fafa;
  background-color: #ffd23f;
  box-shadow: -2px 2px 0 1px #f0fafa;
}

.button:active {
  transform: translateX(-2px) translateY(2px);
}

.btn-cont {
  display: flex;

  gap: 1em;
}

.buttons-cont {
  display: flex;

  gap: 1em;
}
